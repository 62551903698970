import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

import Seo from "../../../components/seo"

import { DesktopLogo, MobileLogo } from "../../../atoms/Logo"

import { IconMail, IconMailSplash } from "../../../atoms/Icons"
import LatestEpisodes from "../widgets/LatestEpisodes"
import ShareSocial from "../widgets/ShareSocial"
import TheHostess from "../widgets/TheHostess"
import { MeetDana } from "../../Buttons"

const Hero = () => {
  return (
    <div>
      <div className="relative top-0 overflow-hidden h-full">
        <Seo title="Home" />

        {/* Mobile ---> */}
        <div className="lg:hidden flex flex-col lg:flex-row items-center gap-8 max-w-mobile lg:max-w-full pb-8 mx-auto z-10">
          <MobileLogo />
          <h1 className="mobile-title text-center">
            Conversations, tools & reflections along the road to becoming a
            Wisdom Woman.
          </h1>
          <StaticImage
            width={230}
            height={356}
            src="../../../images/Picture/HomeMain.jpg"
            placeholder="tracedSVG"
            alt="Dana sitting on a chair"
            className="z-10"
          />
          <p className="mobile-highlights text-center">
            <span className="mobile-paragraph-juicy">The Juicy Pause</span> are
            conversations and musings on the choices we make in our lives, and
            tools that can help us pivot when we need to.
          </p>
          <div className="hidden relative md:grid grid-cols-1 place-items-center xl:px-16 mt-8">
            <div className="self-center py-8">
              <p className="desktop-title text-center max-w-md mx-auto">
                The Hostess
              </p>
              <p className="desktop-paragraph max-w-lg text-center">
                Dana Maxey has jumpedi into podcasting, as a creative way to
                combine her theatrical and vocal talents with the need to share
                vital information, to help women everywhere step into the peeled
                down truth of who they are, and their Juicy purpose here on
                earth.
              </p>
              <MeetDana />
              <div className="absolute top-0 -left-64">
                <StaticImage
                  height={521}
                  src="../../../images/BackgroundElements/Home/Banana-01.png"
                  alt="bananas"
                />
              </div>
            </div>
            <StaticImage
              width={524}
              height={687}
              src="../../../images/Picture/HomeHostess2.jpg"
              placeholder="tracedSVG"
              alt="Dana sitting on a chair"
            />
          </div>
        </div>
        {/* <-- Mobile */}

        {/* Desktop --> */}
        <div className="hidden lg:block space-y-24 pb-24">
          <div className="grid grid-cols-2 place-items-center 2xl:px-32">
            <div className="scale-90 2xl:scale-110 z-10">
              <DesktopLogo />
              <h1 className="desktop-subtitle text-center max-w-5xl mx-auto">
                Conversations, tools & reflections <br /> along the road to
                becoming a
                <br />
                Wisdom Woman.
              </h1>
            </div>
            <div className="h-full flex flex-col items-center justify-center py-8 z-50">
              <StaticImage
                width={524}
                height={687}
                src="../../../images/Picture/HomeMain.jpg"
                placeholder="tracedSVG"
                alt="Dana sitting on a chair"
              />
            </div>
          </div>
          <p className="desktop-highlights text-center">
            <span className="desktop-paragraph-juicy">The Juicy Pause</span> is
            conversations and musings on the choices we <br /> make in our
            lives, and tools that can help us pivot when we need to.
          </p>
          <TheHostess />
          <LatestEpisodes />
          <ShareSocial />
        </div>
        {/* <-- Desktop */}

        {/* Footer --> */}

        {/* MobileFooter /> */}
        <div className="lg:hidden bg-secondaryBackground flex flex-col items-center text-secondary py-8">
          <div className="max-w-mobile text-center flex flex-col gap-2">
            <h2 className="mobile-subtitle ">Get in touch!</h2>
            <p className="mobile-highlights text-center">
              If you have a subject and would like to be interviewed or you have
              a speaker suggestion please send a message.
            </p>
            <div className="flex items-cener gap-1 mx-auto mt-2">
              <IconMail />
              <p className="cards-cta mt-0.5"> madameflore@yahoo.co.uk</p>
            </div>
            <h2 className="mobile-subtitle pt-4">Juicy Sponsors</h2>
            <div className="text-center pb-8">
              <a
                href="https://www.barefootartes.com"
                className="navbar mx-auto block mb-1"
              >
                www.barefootartes.com
              </a>
              <a
                href="https://www.lelamooi.com"
                className="navbar mx-auto mt-2"
              >
                www.lelamooi.com
              </a>
            </div>
          </div>
          <div className="flex justify-center align-center mt-8 -mb-4">
            <p className="text-sm font-rocksalt text-secondary">Designed by</p>
            <a target="_blank" rel="noreferrer" href="https://funksion.site">
              <StaticImage
                height={15}
                src="../../../images/logoF.png"
                alt="funksion logo"
                className="self-center ml-2 mt-0.5"
                placeholder="tracedSVG"
              />
            </a>
          </div>
          <div className="absolute bottom-0 left-0 scale-75 -translate-x-32 translate-y-4 lg:scale-100">
            <StaticImage
              height={120}
              src="../../../images/BackgroundElements/Home/LemonFooter.png"
              alt="Lemon"
              placeholder="tracedSVG"
            />
          </div>
        </div>

        {/* DesktopFooter */}
        <div className="hidden bg-secondaryBackground lg:flex flex-col justify-evenly items-center text-secondary py-8 px-24">
          <div className="flex justify-around items-center w-full">
            <div className="w-1/2 flex flex-col gap-4">
              <h2 className="desktop-subtitle ">Get in touch!</h2>
              <p className="desktop-paragraph">
                If you have a subject and would like to be interviewed or you
                have a speaker, who may be of intertested in the show and their
                contact  information please send  a message. 
              </p>
              <div className="flex items-center gap-1 ">
                <IconMailSplash />
                <p className="navbar"> madameflore@yahoo.uk</p>
              </div>
            </div>

            <div className="flex flex-col xl:w-1/4 gap-2 xl:-ml-32">
              <h2 className="desktop-subtitle">Juicy Sponsors</h2>
              <div className="text-center mr-auto pl-4 pb-8">
                <a
                  href="https://www.barefootartes.com"
                  className="navbar mx-auto block mb-1"
                >
                  www.barefootartes.com
                </a>
                <a
                  href="https://www.lelamooi.com"
                  className="navbar mx-auto mt-2"
                >
                  www.lelamooi.com
                </a>
              </div>
            </div>
            <div className="absolute bottom-0 right-0">
              <StaticImage
                height={153}
                width={320}
                src="../../../images/BackgroundElements/Home/LemonFooter.png"
                alt="Lemon"
                className="-mr-32"
                placeholder="tracedSVG"
              />
            </div>
          </div>

          <div className="flex justify-center align-center mt-8 -mb-4">
            <p className="text-sm font-rocksalt text-secondary">Designed by</p>
            <a target="_blank" rel="noreferrer" href="https://funksion.site">
              <StaticImage
                height={15}
                src="../../../images/logoF.png"
                alt="funksion logo"
                className="self-center ml-2 mt-0.5"
                placeholder="tracedSVG"
              />
            </a>
          </div>
        </div>
      </div>

      {/* Backgrounds */}
      <div className="absolute top-16 lg:top-32 left-0 -ml-64 z-0 scale-90 lg:scale-100">
        <StaticImage
          height={731}
          src="../../../images/BackgroundElements/Home/ovaries.png"
          alt="ovaries"
          placeholder="tracedSVG"
        />
      </div>

      <div className="lg:absolute -top-32 right-0 z-0 hidden lg:block">
        <StaticImage
          height={490}
          width={200}
          src="../../../images/BackgroundElements/Home/Lemondrop-01.png"
          alt="Lemon"
          placeholder="tracedSVG"
        />
      </div>

      <div className="absolute top-3/4 right-0 scale-75 translate-x-4 lg:translate-x-0 lg:scale-100 ">
        <StaticImage
          height={345}
          src="../../../images/BackgroundElements/Home/Mermaid.png"
          alt="Mermaid"
          placeholder="tracedSVG"
        />
      </div>
    </div>
  )
}

export default Hero
