import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { MeetDana } from "../../../Buttons"

const TheHostess = () => {
  return (
    <div>
      <div className="relative grid grid-cols-2 place-items-center  max-w-7xl mx-auto">
        <div className="h-full flex flex-col gap-8 items-center justify-center py-8 mr-auto">
          <p className="desktop-title text-center max-w-md mx-auto">
            The Hostess
          </p>
          <p className="desktop-paragraph max-w-lg text-center mb-8 lg:pb-0">
            Dana Maxey has jumped into podcasting, as a creative way to combine
            her theatrical and vocal talents with the need to share vital
            information, to help women everywhere step into the peeled down
            truth of who we are, and our Juicy purpose here on earth.
          </p>
          <MeetDana />
          <div className="absolute top-0 -left-64 ">
            <StaticImage
              height={521}
              src="../../../../images/BackgroundElements/Home/Banana-01.png"
              alt="bananas"
              placeholder="tracedSVG"
            />
          </div>
        </div>
        <div className="h-full flex gap-4 items-center justify-center py-8">
          <StaticImage
            width={524}
            height={687}
            src="../../../../images/Picture/HomeHostess1.jpg"
            placeholder="tracedSVG"
            alt="Dana sitting on a chair"
          />
          <StaticImage
            width={524}
            height={687}
            src="../../../../images/Picture/HomeHostess2.jpg"
            placeholder="tracedSVG"
            alt="Dana sitting on a chair"
          />
        </div>
      </div>
    </div>
  )
}

export default TheHostess
